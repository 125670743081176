import { TranslatorContext } from "@jutro/locale";
import { withAuthenticationContext } from "gw-digital-auth-react";
import _ from "lodash";
import PropTypes from 'prop-types';
import appConfig from 'app-config';
import queryString from 'query-string';
import { useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import metadata from "./CustomChoosePymtPage.metadata.json5";
import styles from "./CustomChoosePymtPage.module.scss";
import messages from "./CustomChoosePymtPage.messages";
import CustomChoosePymtHeaderComponent from "../../../../applications/account-management/src/components/CustomChoosePymtHeaderComponent/CustomChoosePymtHeaderComponent";
import CustomFooterComponent from "../../../../applications/account-management/src/components/CustomFooterComponent/CustomFooterComponent";
import { renderContentFromMetadata } from "@jutro/uiconfig";
import { CustomPolicyTypeUtil } from "gw-portals-util-js";

function CustomChoosePymtPage(props) {
    const { location, match } = props;
    const translator = useContext(TranslatorContext);
    const policyNumber = _.get(match, 'params.policyNumber');
    const parsedParams = queryString.parse(location.search);
   
     useEffect(() => {
        document.title = 'Make a Payment - American Modern Insurance Group';
        window.scroll(0, 0);
    }, []);   

    const overrideProps = {
        policyInfoTitle: {
            content: CustomPolicyTypeUtil.getChoosePymtPolicyTitle(policyNumber, parsedParams.isHOFlex, parsedParams.policyType)
        },
        impnoticeHeaderText: {
            content: translator(messages.impnoticeHeaderText)
        },
        impnoticeBannerText: {
            content: translator(messages.impnoticeBannerText)
        },
        makeotpHeaderText: {
            content: translator(messages.makeotpHeaderText)
        },
        makeotpInfoText: {
            content: translator(messages.makeotpInfoText)
        },
        makeotpCCButton: {
            content: translator(messages.makeotpCCButton),
            href: appConfig.env.AUTH_MOBILEPAY_URL + `?policyNumber=${policyNumber}&remitType=CC`,
            target: '_blank'
        },
        makeotpEFTButton: {
            content: translator(messages.makeotpEFTButton),
            href: appConfig.env.AUTH_MOBILEPAY_URL + `?policyNumber=${policyNumber}&remitType=MEF`,
            target: '_blank'
        },
        makeautopayHeader: {
            content: translator(messages.makeautopayHeader)
        },
        makeautopayInfoText: {
            content: translator(messages.makeautopayInfoText)
        },
        makeautopayEZPayButton: {
            content: translator(messages.makeautopayEZPayButton),
            href: appConfig.env.EZPAY_URL,
            target: '_blank'
        },
        makephonepayHeader: {
            content: translator(messages.makephonepayHeader)
        },
        makephonepayText: {
            content: translator(messages.makephonepayText)
        },
        makemailpayHeader: {
            content: translator(messages.makemailpayHeader)
        },
        mailpayUSPSContent: {
            content: translator(messages.mailpayuspscontent)
        },
        mailpayUSPSAddress: {
            content: translator(messages.mailpayuspsaddress)
        },
        mailpayUPSContent: {
            content: translator(messages.mailpayupscontent)
        },
        mailpayUPSAddress: {
            content: translator(messages.mailpayupsaddress)
        },
        mailpayInfoContent: {
            content: translator(messages.mailpayinfocontent)
        }

    };

    const resolvers = {
        resolveComponentMap: {
            customchoosepymtheadercomponent: CustomChoosePymtHeaderComponent,
            customfootercomponent: CustomFooterComponent
        },
        resolveClassNameMap: styles
    };

    return renderContentFromMetadata(metadata.pageContent, overrideProps, resolvers);
}

CustomChoosePymtPage.PropTypes = {
    match: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({ push: PropTypes.func }).isRequired,
    location: PropTypes.shape({ productCode: PropTypes.string }).isRequired,
};
export default withAuthenticationContext(withRouter(CustomChoosePymtPage))